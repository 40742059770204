import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1280.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" >
				<path d="M7263 9913 c-7 -2 -13 -15 -13 -28 0 -12 6 -27 13 -32 6 -6 71 -30
142 -53 191 -63 720 -331 750 -380 3 -6 32 -28 63 -50 31 -23 82 -59 112 -81
77 -55 413 -390 463 -461 112 -157 174 -257 231 -374 25 -50 64 -114 88 -142
36 -42 51 -74 90 -194 26 -78 63 -209 83 -290 l35 -148 0 -313 c0 -233 -4
-327 -14 -367 -7 -30 -17 -77 -21 -105 -9 -69 -98 -334 -142 -424 -36 -75
-184 -304 -204 -317 -6 -3 -27 -32 -46 -63 -47 -74 -274 -298 -310 -307 -39
-9 -103 -47 -133 -79 -27 -28 -272 -195 -288 -195 -5 0 -29 -14 -53 -32 -58
-42 -270 -150 -339 -173 -30 -10 -79 -30 -107 -46 -29 -15 -76 -35 -105 -44
-29 -10 -62 -23 -73 -30 -26 -16 -352 -125 -375 -125 -9 0 -50 -11 -91 -25
-102 -35 -263 -75 -302 -75 -18 0 -52 -12 -77 -27 -39 -22 -61 -27 -160 -33
-63 -4 -128 -12 -143 -18 -16 -7 -43 -12 -61 -12 -19 0 -53 -7 -76 -15 -23 -8
-74 -15 -112 -15 -38 0 -84 -7 -105 -15 -22 -9 -68 -15 -122 -15 -61 0 -104
-6 -144 -20 -41 -14 -82 -20 -149 -20 -53 0 -114 -7 -142 -15 -35 -11 -98 -15
-221 -15 -128 0 -184 -4 -215 -15 -64 -23 -1716 -23 -1780 0 -33 12 -92 15
-260 15 -163 0 -229 4 -266 15 -34 10 -96 15 -207 15 -132 0 -167 3 -214 20
-45 16 -83 20 -175 20 -83 0 -130 5 -155 15 -24 10 -71 15 -148 15 -74 0 -127
5 -155 15 -27 9 -80 15 -144 15 -62 1 -117 6 -141 15 -22 8 -76 15 -121 15
-47 0 -100 6 -124 15 -76 27 -127 13 -118 -31 4 -22 113 -57 228 -73 53 -8
209 -45 316 -76 28 -8 61 -15 73 -15 22 0 148 -29 237 -55 28 -8 64 -15 81
-15 17 0 50 -7 73 -15 23 -8 57 -15 75 -15 18 0 52 -7 75 -15 23 -8 57 -15 75
-15 18 0 52 -7 75 -15 23 -8 60 -15 82 -15 21 0 55 -7 75 -15 19 -8 59 -15 88
-15 29 0 72 -7 95 -15 23 -8 64 -15 91 -15 27 0 69 -7 95 -16 25 -8 68 -18 94
-20 27 -2 67 -11 90 -19 24 -8 71 -15 105 -15 34 0 82 -7 105 -15 23 -8 73
-15 110 -15 37 0 87 -7 110 -15 25 -9 77 -15 127 -15 52 0 97 -6 120 -15 22
-9 69 -15 122 -15 49 -1 103 -7 126 -15 26 -10 81 -15 155 -15 74 -1 129 -6
155 -15 29 -11 90 -15 231 -15 147 0 202 -4 235 -15 64 -23 1372 -23 1434 0
28 10 85 14 185 15 100 0 157 5 185 15 22 8 78 14 125 15 47 0 103 7 125 15
22 8 70 14 106 15 37 0 86 7 109 15 23 8 66 15 95 15 29 0 69 7 88 15 20 8 54
15 75 15 22 0 59 7 82 15 23 8 56 15 73 15 17 0 53 7 81 15 28 8 89 24 136 36
47 11 126 34 177 50 50 16 99 29 108 29 20 0 296 91 375 124 120 50 168 69
230 92 78 28 398 188 461 230 24 17 73 45 109 64 71 36 165 97 220 142 52 43
119 93 175 131 66 45 449 428 492 492 39 59 90 125 133 177 17 20 51 75 75
121 24 46 60 108 80 137 20 29 43 80 52 112 14 51 25 66 70 106 30 25 81 86
115 136 33 50 71 102 83 116 12 14 48 64 79 112 31 48 61 90 67 93 5 4 42 55
81 113 39 59 81 118 94 130 29 30 64 101 64 132 0 22 -206 346 -236 371 -8 6
-56 75 -108 153 -51 78 -101 148 -110 155 -10 8 -24 27 -32 42 -7 15 -31 48
-53 73 -21 25 -45 62 -51 83 -25 76 -164 339 -236 447 -40 61 -95 134 -123
163 -27 28 -63 72 -80 97 -17 25 -53 60 -79 77 -26 18 -75 60 -109 94 -56 57
-262 203 -325 232 -15 6 -53 29 -85 50 -68 45 -275 148 -318 159 -16 4 -47 16
-69 26 -21 11 -86 37 -145 58 -58 21 -137 50 -174 64 -38 14 -96 32 -130 40
-65 14 -236 57 -322 81 -106 29 -142 37 -200 45 -33 4 -73 13 -88 19 -16 6
-52 11 -80 11 -29 1 -70 7 -92 15 -22 8 -76 15 -121 15 -47 0 -100 6 -124 15
-40 14 -160 19 -187 8z"/>
<path d="M10846 8797 c-9 -7 -43 -49 -74 -93 -98 -140 -116 -164 -154 -210
-21 -25 -38 -53 -38 -62 0 -22 42 -96 81 -141 48 -57 94 -120 155 -211 31 -47
69 -98 84 -115 16 -16 53 -68 84 -115 107 -161 149 -203 188 -187 8 3 32 52
52 109 21 56 56 153 77 213 22 61 49 128 59 151 11 22 20 45 20 52 0 6 -17 31
-37 55 -21 25 -51 64 -68 87 -16 22 -51 69 -77 103 -26 34 -62 82 -79 107 -18
25 -54 70 -79 100 -26 30 -59 74 -74 97 -44 66 -84 86 -120 60z"/>
<path d="M5838 8164 c-177 -42 -304 -177 -344 -362 -39 -187 6 -380 117 -497
88 -93 202 -135 367 -135 199 0 347 80 420 227 46 93 57 147 57 278 -1 125
-15 197 -57 279 -31 61 -112 140 -174 170 -108 53 -266 70 -386 40z m200 -226
c54 -50 66 -99 67 -263 0 -142 -1 -153 -27 -207 -55 -117 -160 -114 -221 7
-45 90 -48 285 -5 391 40 100 121 131 186 72z"/>
<path d="M1400 8066 l0 -85 33 -3 32 -3 3 -302 2 -303 -35 0 -35 0 0 -90 0
-90 385 0 385 0 0 180 0 181 -112 -3 -113 -3 -5 -85 -5 -85 -88 -3 -87 -3 2
303 3 303 33 3 32 3 0 85 0 84 -215 0 -215 0 0 -84z"/>
<path d="M2240 8066 l0 -85 38 -3 37 -3 3 -302 2 -303 -40 0 -40 0 0 -90 0
-90 225 0 225 0 0 90 0 90 -40 0 -40 0 0 299 c0 325 -3 311 56 311 23 0 24 2
24 85 l0 85 -225 0 -225 0 0 -84z"/>
<path d="M2770 8065 l0 -85 29 0 c16 0 34 -6 40 -14 8 -9 11 -97 9 -302 l-3
-289 -37 -3 -38 -3 0 -90 0 -89 385 0 385 0 0 180 0 181 -112 -3 -113 -3 -3
-87 -3 -88 -89 0 -90 0 0 299 c0 322 -2 311 51 311 17 0 19 8 19 85 l0 85
-215 0 -215 0 0 -85z"/>
<path d="M3560 8065 l0 -85 35 0 c34 0 35 -1 170 -226 l135 -227 0 -78 0 -79
-40 0 -40 0 0 -90 0 -90 220 0 221 0 -3 88 -3 87 -37 3 -38 3 0 80 0 79 128
225 127 225 40 -2 40 -3 3 -87 3 -88 99 0 100 0 0 85 0 85 60 0 60 0 -2 -297
-3 -298 -52 -3 -53 -3 0 -90 0 -89 255 0 255 0 0 90 0 89 -57 3 -58 3 -3 298
-2 297 60 0 60 0 0 -85 0 -85 100 0 100 0 0 175 0 175 -650 0 -651 0 3 -82 3
-83 33 -3 c20 -2 32 -8 30 -15 -10 -36 -120 -237 -128 -232 -4 3 -14 22 -20
43 -7 20 -30 69 -51 108 -22 39 -39 77 -39 83 0 6 14 11 30 11 l30 0 0 85 0
85 -235 0 -235 0 0 -85z"/>
<path d="M6520 8066 l0 -86 35 0 35 0 0 -305 0 -305 -35 0 -35 0 0 -90 0 -90
220 0 220 0 0 90 0 90 -40 0 -40 0 0 109 0 108 113 5 c134 6 209 31 273 90 60
55 78 107 72 210 -3 48 -11 96 -21 114 -25 49 -76 92 -136 116 -53 21 -73 23
-358 26 l-303 3 0 -85z m472 -96 c53 -15 78 -51 78 -112 0 -27 -5 -58 -10 -69
-16 -29 -64 -49 -123 -52 l-52 -2 -3 109 c-1 61 0 116 2 123 6 16 57 17 108 3z"/>
<path d="M7380 8066 l0 -85 38 -3 37 -3 6 -235 c5 -202 9 -244 27 -300 40
-124 107 -197 222 -242 46 -18 76 -22 181 -22 125 -1 127 -1 195 34 158 81
203 215 204 608 l0 162 35 0 35 0 0 85 0 85 -205 0 -206 0 3 -87 3 -88 39 -3
39 -3 -6 -212 c-6 -230 -14 -275 -57 -319 -24 -23 -37 -28 -80 -28 -45 0 -56
4 -85 33 -46 46 -55 102 -55 337 l0 200 35 0 35 0 0 85 0 85 -220 0 -220 0 0
-84z"/>
<path d="M8400 8066 l0 -85 38 -3 37 -3 0 -300 0 -300 -37 -3 -38 -3 0 -90 0
-89 223 2 222 3 0 85 0 85 -43 3 -43 3 3 107 3 107 115 6 c125 7 176 22 245
69 59 41 95 104 102 179 11 116 -36 220 -119 262 -86 44 -128 49 -425 49
l-283 0 0 -84z m483 -100 c54 -22 72 -51 72 -113 0 -49 -3 -58 -32 -83 -23
-21 -46 -30 -86 -35 -31 -4 -60 -4 -66 0 -12 7 -16 227 -4 238 11 12 82 8 116
-7z"/>
<path d="M11449 7811 c-19 -19 -29 -40 -29 -59 0 -16 -13 -67 -30 -113 -16
-46 -30 -93 -30 -105 0 -11 -16 -67 -35 -124 -19 -57 -35 -112 -35 -122 0 -22
104 -338 125 -377 36 -70 92 -45 125 56 13 37 27 73 31 79 5 6 20 42 33 80 13
38 38 96 55 129 17 33 31 68 31 77 0 10 -34 120 -75 245 -90 272 -106 294
-166 234z"/>
<path d="M11095 6952 c-17 -10 -43 -43 -59 -73 -16 -30 -101 -161 -188 -292
-88 -131 -162 -246 -165 -255 -4 -9 -22 -40 -41 -69 -58 -88 -53 -145 20 -233
38 -46 88 -112 138 -182 78 -110 128 -110 189 3 20 36 59 99 86 140 28 40 66
100 84 133 18 32 57 94 87 137 30 43 54 82 54 87 0 4 18 34 40 67 22 33 40 65
40 71 0 5 -17 46 -38 90 -22 43 -45 97 -52 119 -7 22 -27 69 -45 105 -18 36
-38 82 -44 102 -12 36 -43 68 -66 68 -5 0 -24 -8 -40 -18z"/>
<path d="M4725 6910 c-29 -11 -48 -37 -60 -79 -8 -26 -17 -37 -33 -39 -27 -4
-29 -32 -2 -32 24 0 24 0 1 -130 -11 -57 -17 -108 -14 -113 3 -4 12 -7 21 -5
13 2 21 30 37 123 l21 120 48 3 c27 2 52 0 56 -5 4 -4 1 -52 -8 -105 -15 -93
-15 -98 3 -118 23 -25 68 -23 73 3 2 12 -3 17 -17 17 -18 0 -21 6 -21 38 1 20
6 66 13 102 10 55 15 65 36 70 32 7 38 28 9 32 -20 3 -23 8 -20 36 3 30 1 33
-20 30 -17 -2 -24 -11 -28 -33 -5 -28 -8 -30 -58 -33 -52 -3 -52 -3 -52 27 0
31 30 61 61 61 17 0 32 23 22 34 -9 8 -44 6 -68 -4z"/>
<path d="M5806 6893 c-2 -10 -8 -38 -11 -63 -10 -60 -12 -62 -41 -44 -31 21
-88 13 -120 -17 -57 -54 -68 -186 -19 -235 26 -26 72 -32 108 -13 16 9 22 9
25 0 4 -13 28 -15 36 -3 6 10 66 349 66 374 0 24 -37 24 -44 1z m-46 -148 c18
-22 5 -133 -19 -165 -22 -30 -71 -38 -95 -15 -33 33 -12 159 31 184 26 15 69
13 83 -4z"/>
<path d="M6592 6718 c-18 -106 -32 -196 -32 -200 0 -19 40 -6 45 15 4 19 65
368 65 375 0 1 -10 2 -23 2 -22 0 -24 -8 -55 -192z"/>
<path d="M7102 6731 c-18 -98 -32 -188 -32 -200 0 -25 25 -28 38 -5 9 16 11
16 33 0 13 -9 39 -16 57 -16 89 0 156 160 102 247 -27 45 -63 53 -124 27 -18
-7 -19 -4 -12 44 4 29 9 59 12 67 5 11 -1 15 -18 15 -24 0 -26 -4 -56 -179z
m152 13 c11 -10 16 -34 16 -68 0 -92 -59 -149 -120 -116 -22 12 -26 56 -10
125 15 65 76 97 114 59z"/>
<path d="M7392 6727 c-38 -217 -38 -217 -12 -217 11 0 20 2 20 3 0 2 16 91 35
197 19 106 35 195 35 197 0 1 -11 3 -23 3 -23 0 -25 -7 -55 -183z"/>
<path d="M3937 6886 c-50 -18 -64 -31 -77 -72 -18 -52 3 -83 80 -121 94 -46
110 -74 65 -118 -44 -45 -145 -24 -145 30 0 9 -9 15 -25 15 -29 0 -31 -10 -10
-50 39 -76 178 -81 229 -8 20 28 21 87 2 114 -8 11 -44 33 -80 48 -48 21 -68
35 -72 51 -9 35 12 63 55 76 46 14 74 2 96 -41 18 -36 45 -40 45 -7 0 32 -27
67 -66 83 -40 17 -48 17 -97 0z"/>
<path d="M4543 6893 c-18 -6 -16 -40 1 -46 8 -3 20 1 27 9 10 12 9 18 -2 28
-8 8 -20 12 -26 9z"/>
<path d="M6753 6893 c-7 -3 -13 -13 -13 -23 0 -19 17 -30 38 -23 6 3 12 13 12
23 0 19 -17 30 -37 23z"/>
<path d="M2690 6882 c0 -4 -13 -84 -30 -177 -36 -205 -36 -197 -8 -193 20 3
24 13 37 85 l14 82 76 3 c63 3 76 6 79 21 3 15 -5 17 -67 17 -38 0 -72 3 -74
8 -3 4 0 33 5 65 l10 57 88 0 c85 0 100 5 100 36 0 2 -52 4 -115 4 -63 0 -115
-4 -115 -8z"/>
<path d="M6050 6883 c0 -5 -14 -84 -30 -178 -35 -200 -35 -195 -11 -195 22 0
27 10 36 76 11 73 20 84 65 84 l38 0 24 -72 c24 -72 48 -102 67 -83 7 7 1 35
-16 83 l-26 73 36 30 c61 50 63 133 4 169 -24 15 -50 20 -109 20 -43 0 -78 -3
-78 -7z m161 -43 c14 -8 19 -21 19 -50 0 -54 -34 -80 -106 -80 -61 0 -60 -2
-43 83 l12 57 49 0 c27 0 58 -5 69 -10z"/>
<path d="M3495 6848 c-2 -7 -5 -22 -7 -33 -2 -13 -12 -21 -31 -23 -30 -4 -38
-32 -9 -32 10 0 21 -2 24 -5 2 -3 -2 -46 -10 -96 -7 -49 -11 -101 -8 -115 10
-38 86 -47 86 -9 0 8 -9 15 -19 15 -28 0 -30 17 -15 105 17 101 18 103 45 107
31 4 36 26 7 30 -19 2 -23 8 -20 36 2 26 -1 32 -18 32 -11 0 -22 -6 -25 -12z"/>
<path d="M2974 6784 c-17 -8 -36 -26 -42 -40 -10 -21 -9 -24 8 -24 11 0 29 9
40 20 23 23 72 26 90 6 6 -8 10 -25 8 -38 -3 -20 -10 -24 -66 -29 -79 -8 -116
-38 -116 -95 0 -63 63 -92 130 -62 22 10 28 10 32 0 2 -6 11 -12 21 -12 14 0
19 17 29 93 18 134 16 152 -14 176 -31 25 -77 27 -120 5z m96 -143 c0 -21 -22
-65 -38 -77 -23 -18 -67 -17 -82 1 -17 21 -4 64 23 75 30 12 97 12 97 1z"/>
<path d="M3245 6791 c-50 -22 -73 -82 -45 -115 6 -8 33 -23 58 -32 69 -25 79
-37 58 -68 -23 -36 -77 -36 -104 -1 -11 14 -27 25 -36 25 -27 0 -18 -37 16
-65 28 -24 39 -27 82 -22 58 5 96 40 96 86 0 34 -14 47 -77 71 -60 23 -74 42
-49 69 27 30 70 27 94 -6 13 -17 25 -24 36 -20 22 9 20 21 -7 52 -24 28 -86
42 -122 26z"/>
<path d="M5134 6790 c-12 -4 -31 -21 -44 -37 -22 -28 -22 -28 -2 -31 12 -2 30
6 41 17 41 41 106 20 99 -31 -3 -20 -10 -24 -66 -29 -79 -8 -116 -38 -116 -95
0 -63 63 -92 130 -62 22 10 28 10 32 0 2 -6 11 -12 21 -12 14 0 19 17 29 93
18 134 16 152 -14 176 -28 22 -73 26 -110 11z m82 -172 c-8 -39 -40 -67 -77
-68 -34 0 -53 23 -45 55 8 30 35 43 90 44 38 1 38 1 32 -31z"/>
<path d="M5425 6784 c-19 -10 -31 -11 -33 -5 -2 6 -12 11 -22 11 -14 0 -21 -9
-25 -32 -3 -18 -14 -79 -24 -136 -16 -89 -17 -104 -4 -109 24 -9 33 10 49 108
17 105 43 143 92 137 49 -6 50 -22 18 -233 0 -5 8 -10 19 -10 16 0 22 11 33
60 7 33 13 88 15 123 2 56 -1 66 -22 83 -28 23 -56 24 -96 3z"/>
<path d="M6373 6781 c-88 -55 -98 -223 -16 -260 53 -24 143 2 143 41 0 22 -10
23 -36 3 -25 -19 -79 -19 -98 1 -15 14 -17 21 -20 57 -1 8 26 13 89 17 l90 5
0 53 c-1 64 -24 91 -81 98 -28 4 -48 -1 -71 -15z m101 -43 c33 -47 23 -58 -54
-58 -38 0 -70 4 -70 9 0 5 12 23 26 40 32 38 75 42 98 9z"/>
<path d="M6900 6789 c-32 -13 -60 -39 -60 -56 0 -20 33 -16 53 7 20 23 69 27
87 6 6 -8 10 -25 8 -38 -3 -20 -10 -24 -66 -29 -79 -8 -112 -34 -112 -90 0
-66 58 -98 125 -67 19 9 28 9 37 0 26 -26 35 -9 52 98 18 117 16 135 -26 164
-25 18 -61 20 -98 5z m76 -171 c-8 -39 -40 -67 -77 -68 -33 0 -53 23 -45 53 7
29 39 45 90 46 38 1 38 1 32 -31z"/>
<path d="M7560 6778 c-44 -31 -70 -82 -70 -141 0 -89 49 -136 127 -124 45 8
83 41 66 58 -8 8 -17 7 -34 -5 -28 -19 -82 -21 -98 -3 -6 8 -14 28 -17 45 l-6
31 93 3 94 3 3 31 c10 99 -79 156 -158 102z m108 -51 c7 -15 10 -32 7 -37 -7
-12 -135 -14 -135 -2 0 10 31 55 44 65 6 4 24 6 41 5 21 -2 34 -11 43 -31z"/>
<path d="M4120 6783 c0 -5 7 -64 15 -132 21 -178 32 -182 102 -32 49 105 56
106 67 11 19 -157 29 -158 103 -2 79 167 77 162 49 162 -20 0 -28 -13 -61 -95
-21 -52 -42 -95 -46 -95 -3 0 -9 24 -13 53 -17 134 -17 137 -38 137 -15 0 -30
-23 -66 -97 -43 -92 -46 -96 -53 -68 -4 17 -7 53 -8 82 0 28 -4 58 -7 67 -6
16 -44 23 -44 9z"/>
<path d="M4515 6778 c-12 -34 -45 -249 -40 -258 10 -16 33 -11 39 8 3 9 12 60
21 112 8 52 18 107 21 123 5 23 2 27 -16 27 -11 0 -23 -6 -25 -12z"/>
<path d="M6726 6778 c-15 -49 -44 -254 -36 -262 23 -23 37 6 54 111 10 59 20
120 23 136 5 23 2 27 -16 27 -12 0 -23 -6 -25 -12z"/>
<path d="M3576 6523 c-23 -64 -24 -78 -6 -78 18 0 60 72 60 103 0 15 -6 22
-19 22 -13 0 -23 -14 -35 -47z"/>
<path d="M7761 6560 c-19 -11 -9 -45 14 -45 13 0 21 8 23 21 3 23 -17 37 -37
24z"/>
<path d="M7880 6555 c-16 -19 -5 -47 17 -43 11 2 18 12 18 27 0 29 -17 37 -35
16z"/>
<path d="M8000 6554 c-11 -12 -10 -18 3 -32 16 -15 18 -15 34 0 13 14 14 20 3
32 -7 9 -16 16 -20 16 -4 0 -13 -7 -20 -16z"/>
<path d="M12249 2866 c-2 -2 -179 -7 -394 -10 -583 -11 -708 -15 -717 -24 -8
-8 67 -13 162 -11 19 0 60 1 90 1 56 1 135 -21 173 -47 12 -8 27 -15 34 -15 8
0 36 -16 64 -35 28 -19 57 -35 65 -35 8 0 38 -12 66 -27 29 -15 61 -30 70 -35
16 -7 128 -66 193 -101 17 -9 68 -35 115 -57 47 -23 103 -51 125 -64 22 -12
56 -28 75 -36 19 -8 49 -24 66 -37 17 -13 37 -23 45 -23 8 0 31 -11 51 -25 20
-13 61 -36 92 -49 32 -14 69 -34 84 -44 15 -10 42 -24 60 -30 l32 -11 0 358 0
358 -273 2 c-151 1 -275 -1 -278 -3z m-241 -213 c17 -3 32 -9 32 -12 0 -4 10
-7 21 -8 12 -1 36 -15 53 -31 17 -16 45 -33 61 -38 l30 -8 -34 -4 c-34 -4 -84
12 -111 34 -8 7 -28 16 -43 19 -16 4 -35 18 -43 31 -9 12 -21 25 -27 27 -7 2
-3 2 8 0 11 -2 35 -6 53 -10z"/>
<path d="M0 1969 l0 -872 788 5 c677 5 792 8 822 21 30 13 146 16 860 18 454
2 848 2 875 0 28 -1 420 -4 873 -7 811 -4 823 -4 862 16 34 17 46 19 82 10 66
-17 1582 3 1625 21 29 12 34 11 54 -6 20 -19 44 -20 820 -23 706 -2 803 -1
836 13 33 14 131 16 808 16 423 0 801 4 840 8 57 8 75 7 105 -7 32 -16 99 -17
770 -14 462 1 765 7 815 13 44 6 256 13 470 15 215 2 414 6 443 9 l52 6 0 440
c0 418 -1 439 -18 439 -10 0 -36 13 -58 30 -21 16 -43 30 -47 30 -5 0 -23 6
-40 14 -18 7 -51 21 -74 31 -23 10 -44 24 -47 32 -3 8 -10 12 -14 9 -5 -3 -25
9 -44 27 -20 18 -61 41 -92 50 -30 10 -58 22 -61 27 -8 13 -35 13 -35 -1 0 -6
14 -22 32 -37 34 -29 43 -35 109 -71 24 -13 89 -53 144 -90 55 -36 114 -73
130 -81 17 -8 30 -22 30 -30 0 -21 -62 -26 -83 -7 -10 8 -28 17 -42 20 -14 3
-62 19 -107 36 -108 39 -138 42 -138 16 0 -14 10 -23 34 -31 18 -6 41 -21 50
-33 9 -12 27 -23 40 -24 12 -1 27 -9 32 -19 8 -14 6 -16 -8 -11 -10 4 -21 11
-25 16 -3 6 -21 10 -40 9 -44 -1 -140 18 -155 30 -9 7 -13 7 -13 -1 0 -6 -15
-12 -32 -12 -31 -2 -31 -2 -5 -6 25 -4 39 -23 16 -23 -6 0 -9 -12 -7 -27 2
-18 8 -27 17 -25 7 2 23 -6 35 -16 11 -11 31 -25 44 -31 32 -17 28 -47 -7 -59
-21 -7 -34 -7 -48 2 -23 16 -103 39 -110 32 -9 -9 39 -55 77 -75 44 -24 75
-57 61 -66 -6 -3 -43 -6 -82 -7 -68 0 -73 1 -138 46 -37 25 -76 46 -87 46 -10
0 -39 14 -63 30 -51 35 -81 39 -81 11 0 -23 16 -37 53 -46 15 -4 27 -13 27
-21 0 -8 19 -18 45 -25 25 -6 48 -15 50 -20 15 -23 -60 -28 -516 -33 -851 -10
-1203 -16 -1231 -22 -19 -4 -37 1 -59 17 -27 19 -35 20 -52 10 -12 -8 -74 -14
-171 -17 -294 -8 -1441 -11 -1461 -4 -11 4 -31 2 -46 -6 -15 -7 -58 -13 -100
-12 -41 0 -402 -1 -803 -1 -401 -1 -731 1 -733 3 -3 3 -7 26 -9 53 -13 172
-26 384 -34 543 -5 102 -11 201 -14 220 -7 49 -16 192 -16 268 0 55 -2 62 -20
62 -24 0 -24 -5 -10 -170 5 -63 12 -205 15 -314 3 -110 12 -254 20 -320 20
-164 20 -310 -1 -330 -14 -14 -70 -16 -467 -16 -249 -1 -606 -3 -794 -6 -284
-5 -346 -3 -369 9 -22 12 -31 12 -53 1 -20 -10 -198 -14 -839 -17 -774 -4
-814 -3 -825 14 -10 16 -14 16 -38 3 -23 -11 -161 -14 -850 -14 -452 0 -824 2
-827 4 -2 3 20 20 49 39 30 19 65 47 79 62 14 15 54 48 90 73 109 77 166 122
243 194 28 25 62 52 76 60 29 15 55 37 50 43 -8 7 -86 -37 -159 -90 -141 -102
-375 -261 -444 -303 -16 -9 -40 -26 -55 -38 -64 -51 -86 -56 -227 -52 -81 2
-138 8 -145 15 -18 18 17 65 70 91 25 13 73 48 105 77 51 46 58 56 46 69 -7
10 -8 16 -2 16 6 0 10 9 9 19 -1 12 -6 17 -15 14 -11 -5 -11 -7 0 -14 10 -7 9
-9 -5 -9 -10 0 -28 -6 -40 -14 -12 -8 -38 -16 -57 -19 -19 -3 -48 -10 -63 -16
-37 -14 -60 -14 -51 1 5 8 2 9 -12 3 -10 -4 -29 -10 -41 -12 -12 -3 -28 -11
-34 -19 -6 -8 -16 -14 -21 -14 -5 0 -16 -7 -24 -15 -9 -8 -24 -15 -35 -15 -10
0 -19 7 -19 15 0 9 9 15 23 15 13 0 39 15 58 33 19 18 54 44 79 57 25 14 56
35 69 47 13 12 36 25 50 28 25 6 41 21 41 38 0 12 -54 -4 -67 -21 -7 -9 -22
-16 -32 -17 -11 -1 -37 -14 -59 -28 -21 -15 -43 -27 -50 -27 -7 0 -12 -4 -12
-8 0 -11 -95 -59 -134 -68 l-29 -7 24 32 c13 18 32 31 44 31 20 0 65 37 65 53
0 12 -23 7 -41 -9 -13 -12 -22 -13 -38 -5 -11 6 -31 12 -45 13 -39 3 -39 25
-2 43 21 10 33 22 31 33 -1 9 0 11 2 5 3 -7 10 -13 17 -13 15 0 64 36 83 60 7
8 13 11 13 7 0 -4 9 -3 20 3 11 6 19 18 18 28 -3 42 2 52 28 52 31 0 64 17 64
32 0 17 33 37 62 38 29 0 116 66 117 89 1 16 -46 7 -70 -15 -8 -8 -19 -14 -23
-14 -4 0 -22 -10 -39 -22 -35 -26 -57 -39 -104 -63 -18 -10 -33 -24 -33 -31 0
-8 -10 -14 -26 -14 -14 0 -42 -11 -62 -25 -20 -14 -46 -25 -58 -25 -14 0 -24
-8 -27 -22 -4 -16 -19 -27 -49 -36 -24 -8 -80 -31 -125 -53 -44 -21 -86 -39
-92 -39 -14 0 -14 17 0 26 8 4 7 9 -2 15 -11 8 -8 15 15 34 17 13 41 27 55 30
14 4 46 21 71 39 25 17 90 54 145 81 55 27 135 69 178 94 132 77 187 107 307
167 63 31 119 63 123 71 4 7 16 13 25 14 9 0 40 13 67 29 28 16 64 36 80 44
17 8 44 26 60 40 17 14 40 26 51 26 12 0 24 6 27 14 4 9 18 14 36 14 21 -1 38
7 53 23 22 23 29 25 111 22 48 -1 98 2 110 6 21 9 21 9 -6 16 -15 4 -43 5 -62
3 -19 -2 -35 0 -35 4 0 4 -31 8 -68 8 -53 0 -73 -4 -85 -18 -9 -9 -46 -32 -84
-51 -37 -18 -79 -39 -93 -47 -14 -8 -41 -22 -60 -31 -19 -8 -42 -19 -50 -24
-8 -5 -98 -51 -200 -102 -285 -144 -544 -278 -555 -287 -14 -11 -612 -310
-621 -310 -4 0 -60 -27 -126 -60 -65 -33 -123 -60 -128 -60 -20 0 4 32 39 49
36 19 42 21 60 21 6 0 16 12 23 28 9 19 22 28 43 30 38 4 55 20 35 32 -10 6
-2 7 24 3 28 -4 37 -3 32 5 -4 8 0 12 11 12 11 0 28 7 39 15 18 14 17 14 -13
15 -17 0 -33 -5 -35 -11 -2 -6 -13 -9 -23 -6 -19 5 -19 5 2 12 12 4 29 18 38
31 9 14 25 24 39 24 12 0 31 9 41 20 10 11 26 20 35 20 10 0 24 7 31 17 8 9
28 23 44 30 43 21 86 45 115 66 13 9 34 17 45 17 11 0 63 29 115 64 52 36 109
66 125 68 17 2 48 15 70 29 22 14 61 35 88 47 26 12 90 46 142 77 51 30 101
55 110 55 9 0 29 8 43 18 15 10 47 27 72 39 25 12 75 35 112 52 36 17 73 31
82 31 8 0 23 9 33 19 10 11 30 22 46 26 36 10 34 21 -5 29 -18 3 -415 7 -881
8 -467 1 -851 6 -853 10 -3 4 -14 8 -25 8 -19 0 -19 -15 -19 -871z m12785 31
c4 -6 -5 -10 -19 -10 -14 0 -26 5 -26 10 0 6 9 10 19 10 11 0 23 -4 26 -10z
m-12042 -21 c-6 -6 -19 -9 -29 -6 -17 3 -16 5 6 14 28 12 39 8 23 -8z m-83
-39 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m340 -46 c0 -2 -9 -4 -21 -4 -11 0 -18 4 -14 10 5 8 35 3 35 -6z m240 -74 c0
-5 -7 -7 -15 -4 -8 4 -15 8 -15 10 0 2 7 4 15 4 8 0 15 -4 15 -10z m11170 -15
c10 -12 10 -15 -3 -15 -23 0 -47 11 -47 21 0 15 37 10 50 -6z m-11340 -15 c0
-5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-60
-38 c0 -10 -8 -18 -19 -19 -18 -2 -19 0 -3 18 20 23 22 24 22 1z"/>
<path d="M10168 2833 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M10745 2830 c25 -11 289 -11 330 0 17 4 -52 8 -160 8 -114 0 -182 -3
-170 -8z"/>
<path d="M3058 2813 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M4420 2811 c0 -16 28 -20 47 -6 17 13 16 14 -14 15 -18 0 -33 -4 -33
-9z"/>
<path d="M5580 2800 c0 -23 10 -26 28 -8 18 18 15 28 -8 28 -13 0 -20 -7 -20
-20z"/>
<path d="M11342 2788 c-25 -25 -13 -38 35 -38 l46 0 -23 25 c-26 28 -40 31
-58 13z"/>
<path d="M2111 2734 c-35 -27 -40 -34 -23 -34 11 0 23 4 26 9 3 4 18 11 33 14
16 4 39 16 53 27 l25 20 -35 -1 c-24 0 -48 -10 -79 -35z"/>
<path d="M1918 2622 c-42 -30 -47 -42 -18 -42 27 0 70 28 70 46 0 24 -15 22
-52 -4z"/>
<path d="M1778 2554 c-16 -8 -28 -19 -28 -24 0 -8 -142 -80 -160 -80 -10 0
-38 -21 -74 -55 l-21 -20 22 -3 c12 -2 36 6 53 17 16 12 43 30 60 41 16 11 33
20 38 20 5 0 17 9 27 20 10 11 20 18 23 16 4 -4 38 13 122 60 11 6 17 14 13
17 -11 11 -46 7 -75 -9z"/>
<path d="M11839 2539 c23 -19 51 -25 51 -11 0 4 -17 12 -37 19 l-38 11 24 -19z"/>
<path d="M12056 2425 c-3 -9 0 -15 9 -15 16 0 20 16 6 24 -5 3 -11 -1 -15 -9z"/>
<path d="M12170 2360 c0 -11 48 -25 56 -17 4 3 3 10 -1 16 -8 14 -55 14 -55 1z"/>
<path d="M3951 2284 c-7 -8 -11 -17 -9 -20 3 -2 13 5 23 16 10 11 14 20 9 20
-5 -1 -15 -8 -23 -16z"/>
<path d="M1340 2120 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M476 2083 c-3 -3 -3 -10 0 -14 7 -12 34 -11 34 0 0 11 -26 22 -34 14z"/>
<path d="M1197 2026 c-7 -16 1 -26 20 -26 7 0 13 6 13 14 0 8 6 17 13 19 6 3
0 6 -14 6 -15 1 -29 -5 -32 -13z"/>
<path d="M5248 2003 c-20 -24 -51 -110 -45 -126 3 -6 10 2 16 18 7 17 21 49
33 73 22 46 20 63 -4 35z"/>
<path d="M12097 1903 c-14 -13 -6 -23 19 -23 23 0 24 2 10 15 -16 17 -20 18
-29 8z"/>
<path d="M3476 1761 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>

</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
